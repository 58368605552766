/** 
 * 视听云---案例
 */
<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner-one"
      elevation="0"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/other/audioCaseBanner.png "
          height="100%"
          :transition="false"
          class="d-flex align-center">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center align-center">
          <v-card color="transparent" flat width="80%" class="mt-16 d-flex flex-no-wrap justify-center">
            <div >
              <div>
                <v-card-title class="banner-title white--text font-size-48 mb-6 pa-0 justify-center text-align-center">
                  为企业定制视频平台，助力媒体运营 
                </v-card-title>
                <!-- <div class="btnWrap">
                    <p class="banner-btn">立即申请</p>
                </div> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>

    <v-container class="containerStyle" height='500'>
      <div class="public-title">
        <p>WORD OF MOUTH CASES</p>
        <div>
          <span>口碑案例</span>
        </div>
      </div>
      <v-card class="wrap part-one d-flex flex-no-wrap align-center justify-center flex-row-reverse" flat>
        <div class="pl-9 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/aucase1.png" />
        </div>
        <div class="text-wrap" style="padding-left:60px;padding-right: 56px;">
          <v-card class="pa-0"  flat>
            <v-card-title  class="con_tit pa-0 font-size-24">中国农业银行App</v-card-title>
            <v-card-title  class="pa-0 font-size-16  mt-4">视听拍照赋能，把银行App建成自己直播平台</v-card-title>
            <v-card-text  class="con_text pa-0 mt-5 mb-5">借助CITV的网络视听牌照，保障播前、播中、播后安全合规，使农业App同时符合国家广电政策；从界面细节到内容制作，都体现出CITV精益求精的态度</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex align-center justify-center mt-10" flat>
        <div class=" borderimg" >
          <img src="https://h5.ophyer.cn/official_website/other/aucase2.png"/>
        </div>
        <div class="text-wrap" style="padding-right:120px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 font-size-24">宝树号内容聚合平台</v-card-title>
            <v-card-title  class="pa-0 font-size-16  mt-4">完善的内容审核、技术支撑，帮客户保驾护航</v-card-title>
            <v-card-title  class="font-size-14 pa-0 mb-5"></v-card-title>
            <v-card-text  class="con_text pa-0 mb-5">开辟Babytree.citv.cn专有视听板块，助力客户实现合规港股上市需求；上线合规的微信小程序，稳步提升引流效果</v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "audioCase"
}
</script>

<style scoped lang='scss'>
.banner-one .banner-title{
  max-width: 800px;
}
.btnWrap .banner-btn{
  margin-top: 0px;
}
.containerStyle{
  padding-top: 24px;
  padding-bottom: 4px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    img{
      border-radius: 10px;
      float: right;
      // width: 500px;
      // height: 340px;
      margin: 60px;
    }
  }
  .borderimg_two{
    width:500px;
    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    background: #FFFFFF;
    border-radius: 15px;
    // margin-bottom: 60px;
    .text-wrap{
       width: 500px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 20px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>